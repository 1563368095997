import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"

const PageNotFound = () => {
  return (
    <Layout>
      <Helmet>
        <title>404</title>
        <meta name="description" content={"Page not found"} />
      </Helmet>
      <div><h1>404</h1></div>
      </Layout>
   )
  }

export default PageNotFound